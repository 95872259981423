






import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ConfirmPopup from '@/modules/common/components/popup-confirm.vue';
import CompsetSettingsService, { CompsetSettingsServiceS } from '../compset-settings.service';

@Component({
    components: {
        ConfirmPopup,
    },
})
export default class ConfirmClosePopup extends Vue {
    @Inject(CompsetSettingsServiceS) private compsetSettingsService!: CompsetSettingsService;

    close() {
        this.$router.push({
            name: this.$route.matched[this.$route.matched.length - 3].name,
            params: this.$route.params,
            query: this.$route.query,
        });
        this.compsetSettingsService.settingsIsChanged(false);
    }
}
